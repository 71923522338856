<template>
  <div>
    <v-radio-group v-model="data.type">
      <v-radio
        :label="$t('order.action.create.type.without_member')"
        value="without_member"
      ></v-radio>

      <v-radio
        :label="$t('order.action.create.type.member')"
        value="member"
      ></v-radio>
    </v-radio-group>

    <v-btn
      color="primary"
      small
      @click="choiceMember"
    >
      <span>{{'member.action.choice_member'| t}}</span>
      <span v-if="member">( {{$helper.getSiteUserName(member)}} )</span>
    </v-btn>
  </div>
</template>

<script lang="babel" type="text/babel">
export default {
  popupConfig: Object,
  data: () => ({
    data: {
      type: 'member',
      member_id: null,
    },
    member: null,
  }),
  methods: {
    choiceMember() {
      this.$memberSelector({
        applyCallback: data => {
          this.member = data
          this.data.member_id = data.id
        },
      })
    },
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', this.data)
      },
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
